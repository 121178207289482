<template>
  <sidebar-menu class="plk_menu" :menu="menu" expanded="true" :collapsed="true" hideToggle/>
</template>

<script>
import { APP_STYLES_CONFIG_FILE, APP_JAVASCRIPT_CONFIG_FILE, APP_URL_FILENAME_PARAM } from '@/enviroment/app'
import i18n from './i18n'
import { Store, CMS } from '@/store'
import { SidebarMenu } from 'vue-sidebar-menu'
import { VIEWS } from '@/router/design'
import { VIEWS as VIEWS_BASE } from '@/router/base'

export default {
  name: 'plkmenu',
  components: {
    SidebarMenu
  },
  data () {
    return {
    }
  },
  computed: {
    isLogged () {
      return Store.getters[CMS.GETTERS.GET_USER]().token !== undefined
    },
    username () {
      return Store.getters[CMS.GETTERS.GET_USER]().username
    },
    menu () {
      return [
        {
          title: `${this.$t('designer.menu.cms.title')} - ${this.username}`,
          href: `${VIEWS.CMS.LAYER_LIST.path}`,
          icon: 'fas fa-globe',
          child: this.isLogged ? [
            {
              href: VIEWS.CMS.DATABASE_LIST.path,
              title: this.$t('designer.menu.cms.database')
            },
            {
              href: VIEWS.CMS.LAYER_LIST.path,
              title: this.$t('designer.menu.cms.layers')
            },
            {
              href: VIEWS_BASE.LOGOUT.path,
              title: this.$t('designer.menu.cms.logout')
            }

          ] : undefined
        },
        {
          title: this.$t('designer.menu.design'),
          href: VIEWS.DESIGN.path,
          icon: 'fa fa-desktop'
        },
        {
          title: this.$t('designer.menu.styles'),
          href: `${VIEWS.FILE_EDITOR.path}?${APP_URL_FILENAME_PARAM}=${APP_STYLES_CONFIG_FILE}`,
          icon: 'fa fa-palette'
        },
        {
          title: this.$t('designer.menu.javascript'),
          href: `${VIEWS.FILE_EDITOR.path}?${APP_URL_FILENAME_PARAM}=${APP_JAVASCRIPT_CONFIG_FILE}`,
          icon: 'fa fa-code'
        },
        {
          title: this.$t('designer.menu.upload'),
          href: VIEWS.FILES.path,
          icon: 'fa fa-file'
        },
        {
          title: this.$t('designer.menu.import'),
          href: VIEWS.IMPORT.path,
          icon: 'fa fa-upload'
        },
        {
          title: this.$t('designer.menu.export'),
          href: VIEWS.EXPORT.path,
          icon: 'fa fa-download'
        }
      ]
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
    }
  }
}
</script>

<style lang="scss">
  .plk_menu.v-sidebar-menu {
    z-index: 10;
    .vsm--link_level-1 .vsm--icon {
      background-color: #2a2a2e;
    }
    .vsm--mobile-bg {
      background-color: $color-base !important;
      color: white !important;
    }
    .vsm--link_level-1.vsm--link_exact-active {
      -webkit-box-shadow: 3px 0px 0px 0px $color-base inset;
      box-shadow: 3px 0px 0px 0px $color-base inset;
    }
  }
  .plk_menu {
    debar-menu {
      .vsm--toggle-btn {
        padding: 0;
      }
    }
  }
  .plk_menu.v-sidebar-menu.vsm_collapsed {
    .vsm--link_level-1.vsm--link_hover {
      .vsm--icon {
        background-color: $color-base !important;
        color: white !important;
      }
    }
    .vsm--link_level-1 {
      &:hover {
        .vsm--icon {
          background-color: $color-base !important;
          color: white !important;
        }
      }
    }
  }
  .plk_menu.v-sidebar-menu.vsm_expanded {
    .vsm--item_open {
      .vsm--link_level-1 {
        background-color: $color-base !important;
        color: white !important;
        .vsm--icon {
          background-color: $color-base !important;
          color: white !important;
        }
      }
    }
  }
  .plk_menu.v-sidebar-menu.vsm_white-theme.vsm_collapsed {
    .vsm--link_level-1.vsm--link_hover {
      .vsm--icon {
        background-color: $color-base !important;
        color: white !important;
      }
    }
    .vsm--link_level-1 {
      &:hover {
        .vsm--icon {
          background-color: $color-base !important;
          color: white !important;
        }
      }
    }
  }
  .plk_menu.v-sidebar-menu.vsm_white-theme {
    .vsm--mobile-bg {
      background-color: $color-base !important;
      color: white !important;
    }
    .vsm--item_open {
      .vsm--link.vsm--link_mobile-item {
        background-color: $color-base !important;
        color: white !important;
      }
    }
  }
  .plk_menu.v-sidebar-menu.vsm_white-theme.vsm_expanded {
    .vsm--item_open {
      .vsm--link_level-1 {
        background-color: $color-base !important;
        color: white !important;
        .vsm--icon {
          background-color: $color-base !important;
          color: white !important;
        }
      }
    }
  }
  .v-sidebar-menu {
    .vsm--link_level-1.vsm--link_active {
      -webkit-box-shadow: 3px 0px 0px 0px $color-base inset;
      box-shadow: 3px 0px 0px 0px $color-base inset;
    }
  }

</style>
<style scoped lang="scss">
  .plk_menu {
    .logo {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 5px;
    }
    a {
      color: white;
      text-decoration: none;
      display: block;
      font-weight: bold;
      padding: 5px;
      font-size: 0.9em;
      &:hover {
        color: #23001e;
      }
    }
    a.router-link-exact-active {
      color: #ffba49;
    }
  }
  @media (max-width: breakpoint-max('md', $grid-breakpoints)) {
    .plk_menu {
      a {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  @media (max-width: breakpoint-max('sm', $grid-breakpoints)) {
    .plk_menu {
      .logo {
        height: 50px;
        width: auto;
        margin-bottom: 10px;
      }
      a {
        text-align: center;
      }
    }
  }
</style>
